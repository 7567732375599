import { appActions, appSelectors } from 'providers/AppProvider/data';
import { useDispatch, useSelector } from 'react-redux';

export const useCartProducts = () => {
  const dispatch = useDispatch();
  const cartProducts = useSelector(appSelectors.cartProducts);

  const getCartProducts = async () => {
    if (cartProducts.length) return;
    try {
      await dispatch(appActions.getCartProducts());
    } catch (e) {
      console.warn(e);
    }
  };

  return {
    getCartProducts,
  };
};
