import React, { memo, useEffect } from 'react';
import { useStyles } from './styles';
import { useCartProducts } from 'hooks/useCartProducts';
import { useSelector } from 'react-redux';
import { appSelectors } from 'providers/AppProvider/data';

const CartComponent = () => {
  const classes = useStyles();
  const products = useSelector(appSelectors.cartProducts);

  const { getCartProducts } = useCartProducts();

  useEffect(() => {
    getCartProducts();
  }, []);

  return (
    <div className={classes.main}>
      <h1 className={classes.title}>Корзина</h1>
    </div>
  );
};

export default memo(CartComponent);
