import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { useStyles } from './styles';

const GrowMenu = ({
  className,
  children,
  label,
  component: Component,
  endIcon,
  toggleOpen,
  openEndIcon,
  poppersClassName,
  poppersStyle,
  open,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <InvisibleBlock
        show={open}
        onClick={toggleOpen}
      />

      <Component
        className={`${classes.button} ${className}`}
        classes={{ endIcon: classes.endIcon }}
        onClick={toggleOpen}
        endIcon={open ? openEndIcon : endIcon}
        {...props}
      >
        {label}
        <Grow
          in={open}
          className={`${classes.grow} ${poppersClassName}`}
          style={{ ...poppersStyle }}
          timeout={400}
        >
          <Paper
            elevation={5}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </Paper>
        </Grow>
      </Component>
    </>
  );
};

const InvisibleBlock = ({ show, onClick }) => {
  const classes = useStyles();
  if (show) {
    return (
      <div
        onClick={onClick}
        className={classes.unvisableBlock}
      />
    );
  }
  return null;
};

GrowMenu.propTypes = {
  label: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any,
  endIcon: PropTypes.any,
  style: PropTypes.object,
  open: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

GrowMenu.defaultProps = {
  component: Button,
};

export default GrowMenu;
