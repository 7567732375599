import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import HomeIcon from 'assets/images/navigation/home.svg';
import StarIcon from 'assets/images/serdce.svg';
import EmailIcon from 'assets/images/navigation/email.svg';
import UserIcon from 'assets/images/navigation/user.svg';

import HomeFilledIcon from 'assets/images/navigation/home_filled.svg';
import StarFilledIcon from 'assets/images/navigation/serdce_filled.svg';
import EmailFilledIcon from 'assets/images/navigation/email_filled.svg';
import UserFilledIcon from 'assets/images/navigation/user_filled.svg';

import HideOnScroll from 'components/HideOnScroll';
import { useStyles } from './styles';
import { BusinessMobileHeader } from 'business/config/ui/BusinessMobileHeader';

function MobileMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { user, openAuthForm } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = useCallback(
    (_, newValue) => {
      const locations = {
        0: '/',
        1: '/favorites',
        2: '/post',
        3: '/chats',
        4: '/profile/?tab=products',
      };

      const secure = [1, 2, 3, 4];

      if (!user && secure.includes(newValue)) {
        openAuthForm('login');
      } else {
        setValue(newValue);
        history.push(locations[newValue]);
      }
    },
    [user, openAuthForm, history],
  );

  useEffect(() => {
    if (!user) return;

    const paths = {
      '/favorites': 1,
      '/post': 2,
      '/chats': 3,
      '/profile': 4,
    };

    if (paths[location.pathname]) {
      setValue(paths[location.pathname]);
    }
  }, [location, user]);

  if (location.pathname.includes('/products')) {
    return null;
  }

  return (
    <HideOnScroll {...props}>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          icon={
            <img
              src={value === 0 ? HomeFilledIcon : HomeIcon}
              alt=""
              width="24"
              height="24"
            />
          }
          className={classes.navBtn}
          label="Главная"
        />
        <BottomNavigationAction
          icon={
            <img
              alt=""
              src={value === 1 ? StarFilledIcon : StarIcon}
              width="24"
              height="24"
            />
          }
          className={classes.navBtn}
          label="Избранное"
        />
        <BottomNavigationAction
          component="div"
          icon={
            <Fab className={classes.fab}>
              <AddIcon />
            </Fab>
          }
          className={classes.navBtnFab}
          label="Подать"
        />
        <BottomNavigationAction
          icon={
            <img
              alt=""
              src={value === 3 ? EmailFilledIcon : EmailIcon}
              width="24"
              height="24"
            />
          }
          className={classes.navBtn}
          label="Чаты"
        />
        <BottomNavigationAction
          icon={
            <img
              alt=""
              src={value === 4 ? UserFilledIcon : UserIcon}
              width="24"
              height="24"
            />
          }
          className={classes.navBtn}
          label="Профиль"
        />
      </BottomNavigation>
    </HideOnScroll>
  );
}

MobileMenu.propTypes = {
  openAuthForm: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

export default MobileMenu;
