import { createActions } from 'redux-actions';

import {
  createRequestAction,
  getResponseError,
  delLocal,
} from 'common/helpers';

import {
  removeTokensFromCookies,
  setTokenToCookies,
  setRefreshTokenToCookies,
} from 'utils/cookies';
import { ENDPOINTS } from './constants';
import { async } from 'validate.js';

import { profileActions } from 'states/Profile';

const actionCreators = createActions({
  LOGOUT: null,
  CLEAR_PROFILE: null,
  ...createRequestAction('LOGIN'),
  ...createRequestAction('SOCIAL_LOGIN'),
  ...createRequestAction('REFRESH_TOKEN'),
  ...createRequestAction('TOKEN_LOGIN'),
  ...createRequestAction('UPDATE_LOCATION'),
  ...createRequestAction('REGISTER'),
  ...createRequestAction('SET_PASSWORD'),
  ...createRequestAction('RECOVERY'),
  ...createRequestAction('ACTIVATE'),
  ...createRequestAction('SEND_CODE'),
  ...createRequestAction('DELETE'),
});

export function sendCode(params, isPasswordRecovery) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.sendCodeRequest());

    try {
      const res = await api.post(ENDPOINTS.SEND_CODE, params);
      dispatch(actionCreators.sendCodeSuccess());
      if (isPasswordRecovery && res?.data?.code) {
        return res?.data?.code;
      }

      return res;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.sendCodeFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function login(data) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.loginRequest());

    try {
      const res = await api.post(ENDPOINTS.LOGIN, data);
      setTokenToCookies(res.data.access);
      setRefreshTokenToCookies(res.data.refresh);

      dispatch(actionCreators.loginSuccess(res.data));

      return res;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.loginFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function register(params) {
  return async (dispatch, _, { api }) => {
    dispatch(actionCreators.registerRequest());
    try {
      const { data } = await api.post(ENDPOINTS.SIGNUP, params);
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.registerFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function activate(params) {
  return async (dispatch, _, { api }) => {
    dispatch(actionCreators.activateRequest());

    try {
      const { data } = await api.post(ENDPOINTS.ACTIVATE, params);
      if (data?.data?.access) {
        setTokenToCookies(data?.data?.access);
        setRefreshTokenToCookies(data?.data?.refresh);
      }
      dispatch(actionCreators.activateSuccess(data?.data));

      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.activateFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function socialLogin({ data }) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.socialLoginRequest());
    try {
      setTokenToCookies(data?.access_token);
      setRefreshTokenToCookies(data?.refresh_token);
      const getData = await api.get(ENDPOINTS.TOKEN_LOGIN, {
        headers: {
          Authorization: `Bearer ${data?.access_token}`,
        },
      });
      const formData = new FormData();
      formData.append('name', data?.user?.first_name);
      await dispatch(profileActions.updateProfile(formData));
      const formatted = {
        info: {
          ...getData?.data,
          name: data?.user?.first_name,
        },
        refresh: data?.refresh_token,
        token: data?.access_token,
      };
      dispatch(actionCreators.socialLoginSuccess(formatted));
      return data;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.socialLoginFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function refreshToken(data) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.refreshTokenRequest());

    try {
      const res = await api.post(ENDPOINTS.REFRESH_TOKEN, data);
      setTokenToCookies(res.data.access);

      dispatch(actionCreators.refreshTokenSuccess(res.data.access));

      return res;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.refreshTokenFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function setPassword(data) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.setPasswordRequest());

    try {
      const res = await api.post(ENDPOINTS.SET_PASSWORD, data);

      dispatch(actionCreators.setPasswordSuccess(res.data.access));

      return res;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.setPasswordFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function tokenLogin(data) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.tokenLoginRequest());

    try {
      const res = await api.get(ENDPOINTS.TOKEN_LOGIN, data);

      dispatch(actionCreators.tokenLoginSuccess(res.data));

      return res;
    } catch (e) {
      dispatch(actionCreators.tokenLoginFail(e));
      return Promise.reject(e);
    }
  };
}

export function updateLocation(data) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.updateLocationRequest());

    try {
      const res = await api.patch(ENDPOINTS.PROFILE, data);

      dispatch(actionCreators.updateLocationSuccess(res.data));

      return res;
    } catch (e) {
      dispatch(actionCreators.updateLocationFail(e));
      return Promise.reject(e);
    }
  };
}

export function recovery(data) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.recoveryRequest());

    try {
      const res = await api.post(ENDPOINTS.RECOVERY, data);
      setTokenToCookies(res.data.data.access);
      setRefreshTokenToCookies(res.data.data.refresh);

      dispatch(actionCreators.recoverySuccess(res?.data?.data));

      return res;
    } catch (e) {
      const error = getResponseError(e);
      dispatch(actionCreators.recoveryFail(error || e));
      return Promise.reject(error);
    }
  };
}

export function checkCode(params) {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(ENDPOINTS.CHECK_CODE, params);
      if (data.status === 'Founded') {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };
}

export const logOut = () => async (dispatch) => {
  removeTokensFromCookies();
  dispatch(actionCreators.clearProfile());
  await delLocal('__ts:post');
  await dispatch(actionCreators.logout());
};

export const actions = {
  ...actionCreators,
  login,
  socialLogin,
  refreshToken,
  tokenLogin,
  logOut,
  updateLocation,
  register,
  setPassword,
  recovery,
  activate,
  sendCode,
  checkCode,
};
