import { defineMessages } from 'react-intl';

export const scope = 'Profile';

export default defineMessages({
  myProducts: {
    id: `${scope}.myProducts`,
    defaultMessage: 'Мои объявления',
  },
  messages: {
    id: `${scope}.messages`,
    defaultMessage: 'Сообщения',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Натройки',
  },
  actives: {
    id: `${scope}.actives`,
    defaultMessage: 'Активные объявления',
  },
  views: {
    id: `${scope}.views`,
    defaultMessage: 'Просмотры',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Пароль',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Сохранить',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Отменить',
  },
  oldPassword: {
    id: `${scope}.oldPassword`,
    defaultMessage: 'Старый пароль',
  },
  newPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'Новый пароль',
  },
  newPasswordOk: {
    id: `${scope}.newPasswordOk`,
    defaultMessage: 'Подтвердить новый пароль',
  },
  repeatPassword: {
    id: `${scope}.repeatPassword`,
    defaultMessage: 'Повторите пароль',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Имя',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'Все',
  },
  purchase: {
    id: `${scope}.purchase`,
    defaultMessage: 'Покупка',
  },
  sale: {
    id: `${scope}.sale`,
    defaultMessage: 'Продажа',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Уведомления',
  },
  deletedProduct: {
    id: `${scope}.deletedProduct`,
    defaultMessage: 'Продукт удалён',
  },
  deletedProductException: {
    id: `${scope}.deletedProductException`,
    defaultMessage: 'Ошибка при удалении продукта',
  },
  deletedUser: {
    id: `${scope}.deletedUser`,
    defaultMessage: 'Удаленный пользователь',
  },
  blockUser: {
    id: `${scope}.blockUser`,
    defaultMessage: 'Заблокировать пользователя',
  },
  unBlockChat: {
    id: `${scope}.unBlockChat`,
    defaultMessage: 'Разблокировать пользователя',
  },
  deleteChat: {
    id: `${scope}.deleteChat`,
    defineMessages: 'Удалить чат',
  },
  negotiable: {
    id: `${scope}.negotiable`,
    defaultMessage: 'Договорная',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Отправить',
  },
  notifySecondaryText: {
    id: `${scope}.notifySecondaryText`,
    defaultMessage: 'Пользователь {name} добавил ваше объявление в избранные',
  },
  favorites: {
    id: `${scope}.favorites`,
    defaultMessage: 'Избранные',
  },
  noChats: {
    id: `${scope}.noChats`,
    defaultMessage: 'Чатов пока нет',
  },
  noChatsText: {
    id: `${scope}.noChatsText`,
    defaultMessage: 'Здесь будут отображаться ваши переписки',
  },
  noNotifys: {
    id: `${scope}.noNotifys`,
    defaultMessage: 'Уведомлений пока нет',
  },
  noNotifysText: {
    id: `${scope}.noNotifysText`,
    defaultMessage: 'Здесь будут отображаться ваши уведомления',
  },
  productAtivatedText: {
    id: `${scope}.productAtivatedText`,
    defaultMessage: 'Ваше объявление успешно активировано',
  },
  productBlockedText: {
    id: `${scope}.productBlockedText`,
    defaultMessage:
      ' Ваше объявление было заблокировано модератором по причине несоответствия правилам размещения объявлений на сервисе Tez Sat',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Активные',
  },
  blocked: {
    id: `${scope}.active`,
    defaultMessage: 'Заблокированные',
  },
  chats: {
    id: `${scope}.chats`,
    defaultMessage: 'Чаты',
  },
  profileEdit: {
    id: `${scope}.profileEdit`,
    defaultMessage: 'Изменить профиль',
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: 'Местоположение',
  },
  locationText: {
    id: `${scope}.locationText`,
    defaultMessage: 'Показывает только объявления выбранного местоположения',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Категория',
  },
  categoryText: {
    id: `${scope}.categoryText`,
    defaultMessage: 'Показывает только объявления выбранной категории',
  },
  chooseLanguage: {
    id: `${scope}.chooseLanguage`,
    defaultMessage: 'Выбрать язык',
  },
  changePassword: {
    id: `${scope}.changePassword`,
    defaultMessage: 'Пароль',
  },
  passwordSuccessfullyChanged: {
    id: `${scope}.passwordSuccessfullyChanged`,
    defaultMessage: 'Пароль успешно изменен',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'Помощь',
  },
  instruction: {
    id: `${scope}.instruction`,
    defaultMessage: 'Инструкция и руководство',
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: 'Пользовательское соглашение',
  },
  exit: {
    id: `${scope}.exit`,
    defaultMessage: 'Выход',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Номер телефона',
  },
  registerFrom: {
    id: `${scope}.registerFrom`,
    defaultMessage: 'Вы зарегистрированы через:',
  },
  activeNotify: {
    id: `${scope}.activeNotify`,
    defaultMessage: 'Активно',
  },
  blockedNotify: {
    id: `${scope}.blockedNotify`,
    defaultMessage: 'Заблокировано',
  },
  favoriteNotify: {
    id: `${scope}.favoriteNotify`,
    defaultMessage: 'Избранное',
  },
  personalData: {
    id: `${scope}.personalData`,
    defaultMessage: 'Личные данные',
  },
  photoProfile: {
    id: `${scope}.photoProfile`,
    defaultMessage: 'Фото профиля',
  },
  changeProfile: {
    id: `${scope}.changeProfile`,
    defaultMessage: 'Изменить фото',
  },
  delationPhoto: {
    id: `${scope}.delationPhoto`,
    defaultMessage: 'Удалить',
  },
  editPhoto: {
    id: `${scope}.editPhoto`,
    defaultMessage: 'Изменить',
  },
  addPhoto: {
    id: `${scope}.addPhoto`,
    defaultMessage: 'Добавить фото',
  },
  info: {
    id: `${scope}.info`,
    defaultMessage: 'Информация',
  },
  moreMenu: {
    id: `${scope}.moreMenu`,
    defaultMessage: 'Меню действий',
  },
  writeEmail: {
    id: `${scope}.writeEmail`,
    defaultMessage: 'Написать на почту',
  },
  makeCall: {
    id: `${scope}.makeCall`,
    defaultMessage: 'Позвонить',
  },
  chatDeletedSuccess: {
    id: `${scope}.chatDeletedSuccess`,
    defaultMessage: 'Чат успешно удален',
  },
  chatDeletedError: {
    id: `${scope}.chatDeletedError`,
    defaultMessage: 'Ошибка при удалении чата',
  },
  successBlock: {
    id: `${scope}.successBlock`,
    defaultMessage: 'Пользователь успешно заблокирован',
  },
  successUnblock: {
    id: `${scope}.successUnblock`,
    defaultMessage: 'Пользователь успешно разблокирован',
  },
  errorBlock: {
    id: `${scope}.errorBlock`,
    defaultMessage: 'Не получилось заблокировать пользователя',
  },
  errorUnBlock: {
    id: `${scope}.errorUnBlock`,
    defaultMessage: 'Не получилось разаблокировать пользователя',
  },
  errorDeleteRoom: {
    id: `${scope}.errorDeleteRoom`,
    defaultMessage: 'Не получилось удалить чат',
  },
  loadImage: {
    id: `${scope}.loadImage`,
    defaultMessage: 'Загрузить фото',
  },
  editImage: {
    id: `${scope}.editImage`,
    defaultMessage: 'Редактировать фото',
  },
  chooseDifferent: {
    id: `${scope}.chooseDifferent`,
    defaultMessage: 'Выбрать другую',
  },
  deleteProfileConfirm: {
    id: `${scope}.deleteProfileConfirm`,
    defaultMessage: 'Вы уверены что хотите удалить свой аккаунт?',
  },
  deleteConfirm: {
    id: `${scope}.deleteConfirm`,
    defaultMessage: 'Удалить',
  },
  deleteCancel: {
    id: `${scope}.deleteCancel`,
    defaultMessage: 'Отмена',
  },
  deleteProfile: {
    id: `${scope}.deleteProfile`,
    defaultMessage: 'Удалить аккаунт',
  },
});
