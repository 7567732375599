export const ENDPOINTS = {
  LOGIN: '/auth/v1/login/',
  SOCIAL_LOGIN: '/auth/v1/social_login/',
  REFRESH_TOKEN: '/auth/v1/refresh/',
  TOKEN_LOGIN: '/auth/v1/profile/',
  PROFILE: '/auth/v1/profile/',
  SIGNUP: '/auth/v2/signup/',
  ACTIVATE: '/auth/v2/activate/',
  DELETE: '/auth/v1/profile/',

  SET_PASSWORD: '/auth/v1/profile/set_password/',
  RECOVERY: '/auth/v2/passwords/update-password/',
  SEND_CODE: '/auth/v2/passwords/send-code/',

  CHECK_CODE: '/auth/v2/check_code/',
  GET_USER_DATA: '/auth/v1/users/',
};
