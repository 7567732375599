import React, { memo, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';

import Search from 'containers/Search';
import IconCategories from 'containers/IconCategories';
import HideOnScroll from 'components/HideOnScroll';
import { UserContext } from 'providers/UserProvider';

import GooglePlay from 'assets/images/GooglePlayWhite.svg';
import Logo from 'assets/images/logo.svg';
import MenuIcon from '@material-ui/icons/Menu';

import HeaderSelect from 'components/GrowMenu';
import { NavLink } from 'react-router-dom';

import { compose } from 'redux';
import { Box, MenuItem } from '@material-ui/core';
import messages from '../../messages/Header';
import { useStyles } from './styles';
import MoreIcon from '../../assets/images/more.svg';
import MobileHeaderMenu from './components/Menu';
import ToggleLocale from 'containers/ToggleLocale';
import { useSelector } from 'react-redux';
import { authSelectors } from 'services/auth';

function WithSearchBar(props) {
  const classes = useStyles();
  const isAuth = !!useSelector(authSelectors.getAuthUser);

  const { user, logout } = useContext(UserContext);

  const isMobile = useMediaQuery('(max-width:720px)');

  const isBigMobile = useMediaQuery('(max-width:560px)');

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = () => setMenuOpen((prevState) => !prevState);

  const { intl, children, ...rest } = props;

  const AppBar = useMemo(() => {
    return isMobile ? HideOnScroll : React.Fragment;
  }, [isMobile]);

  const appBarProps = useMemo(() => {
    return isMobile
      ? {
          ...props,
          direction: 'down',
        }
      : {};
  }, [isMobile, props]);

  const BlogLink = ({ className }) => (
    <MenuItem
      component={NavLink}
      to="/blog/main"
      className={className}
    >
      {intl.formatMessage({ ...messages.blog })}
    </MenuItem>
  );
  const AboutLink = ({ className }) => (
    <MenuItem
      component={NavLink}
      to="/about"
      className={className}
    >
      {intl.formatMessage({ ...messages.aboutUs })}
    </MenuItem>
  );
  const SupportServiceLink = ({ className }) => (
    <MenuItem
      component="a"
      target="_blank"
      rel="noopener noreferer"
      href="https://api.whatsapp.com/send/?phone=996707797800"
      disableelevation={true}
      disableRipple
      // disableFocusRipple
      disableTouchRipple
      className={className}
    >
      {intl.formatMessage({ ...messages.supportService })}
    </MenuItem>
  );
  const QALink = ({ className }) => (
    <MenuItem
      component={NavLink}
      to="/qa"
      className={className}
    >
      {intl.formatMessage({ ...messages.questionsAndAswers })}
    </MenuItem>
  );
  const LogoutBtn = ({ className }) =>
    user ? (
      <MenuItem
        className={className}
        onClick={logout}
      >
        {intl.formatMessage({ ...messages.logout })}
      </MenuItem>
    ) : null;

  return (
    <div
      className={classes.root}
      {...rest}
    >
      <AppBar {...appBarProps}>
        <div className={isMobile ? classes.appBar : null}>
          {isMobile ? (
            <div className={classes.appButtonsWrap}>
              <a href="/">
                <img
                  alt="Logo"
                  src={Logo}
                  width="140"
                  height="27"
                />
              </a>
              {isBigMobile ? <div className={classes.grow} /> : null}
              <Button
                component="a"
                target="_blank"
                rel="noopener noreferer"
                href="https://play.google.com/store/apps/details?id=com.tezsat.tezsat"
                className={classes.appButton}
                // disableelevation
                disableRipple
                // disableFocusRipple
                disableTouchRipple
              >
                <img
                  alt=""
                  src={GooglePlay}
                  width="100%"
                />
              </Button>
              <Button
                component={NavLink}
                to="/business-advantages"
                className={classes.forBusiness}
              >
                {intl.formatMessage({ ...messages.forBusiness })}
              </Button>
              {!isBigMobile ? (
                <>
                  <div className={classes.grow} />
                  <HeaderSelect
                    open={menuOpen}
                    toggleOpen={toggleMenuOpen}
                    className={classes.menu}
                    label={<MenuIcon />}
                    poppersStyle={{ top: '110%', right: 10, padding: 5 }}
                  >
                    <Box
                      padding="5px 0"
                      component="div"
                      color="#000"
                    >
                      <BlogLink className={classes.menuItem} />
                      <AboutLink className={classes.menuItem} />
                      {/* <AssistanceLink className={classes.menuItem} /> */}
                      <SupportServiceLink className={classes.menuItem} />
                      <QALink className={classes.menuItem} />
                      <LogoutBtn className={classes.menuItem} />
                      {!isAuth && (
                        <ToggleLocale
                          id="clickableItem"
                          className={classes.menuChangeLanguage}
                          style={{
                            marginRight: user ? 20 : 0,
                            marginLeft: 16,
                            display: 'block',
                          }}
                        />
                      )}
                    </Box>
                  </HeaderSelect>
                </>
              ) : (
                <>
                  <Button
                    onClick={toggleMenuOpen}
                    style={{ minWidth: 20, marginLeft: 10 }}
                  >
                    <img
                      src={MoreIcon}
                      alt="menu"
                    />
                  </Button>
                </>
              )}
            </div>
          ) : null}
          <div>
            {isBigMobile ? (
              <MobileHeaderMenu
                open={menuOpen}
                toggleOpen={toggleMenuOpen}
              >
                {/* <AssistanceLink className={classes.mobileMenuItem} /> */}
                <BlogLink className={classes.mobileMenuItem} />
                <AboutLink className={classes.mobileMenuItem} />
                <SupportServiceLink className={classes.mobileMenuItem} />
                <QALink className={classes.mobileMenuItem} />
                <LogoutBtn className={classes.mobileMenuItem} />
                {!isAuth && (
                  <ToggleLocale
                    id="clickableItem"
                    className={classes.menuChangeLanguage}
                    style={{
                      marginLeft: 'auto',
                      marginRight: 16,
                      display: 'block',
                    }}
                    paperStyle={{ right: 0 }}
                  />
                )}
              </MobileHeaderMenu>
            ) : null}
          </div>
          <div className={classes.ad}>
            <ins
              data-revive-zoneid="1"
              data-revive-id="75f0093af11ec8daebaa5d426caab1bc"
            />
          </div>
        </div>
      </AppBar>
      {isMobile && <Search />}
      <IconCategories />
      <main className={classes.content}>{children}</main>
    </div>
  );
}

WithSearchBar.propTypes = {
  intl: intlShape,
  children: PropTypes.node.isRequired,
};

export default compose(injectIntl, memo)(WithSearchBar);
