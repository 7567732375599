import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';
import pencilIcon from 'business/config/assets/pencil.svg';
import settingsIcon from 'business/config/assets/settings.svg';
import { DefaultTypography } from '../BusinessTypography';
import { UserContext } from 'providers/UserProvider';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,

    '& p': {
      color: theme.palette.primary.dark,
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 400,
    },
  },
  profileText: {
    gridColumn: 2,
  },
  settings: {
    width: 'fit-content',
    marginInline: 'auto',
  },
}));

export const BusinessMobileHeader = ({ intl }) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <div className={classes.root}>
      {user?.info?.is_business && (
        <IconButton
          component={Link}
          to="/profile/edit"
        >
          <img
            alt="Изменить"
            src={pencilIcon}
            width="24"
            height="24"
          />
        </IconButton>
      )}
      <DefaultTypography className={classes.profileText}>
        Профиль
      </DefaultTypography>
      <IconButton
        component={Link}
        to="/settings"
        className={classes.settings}
      >
        <img
          alt="Настройки"
          src={settingsIcon}
          width="24"
          height="24"
        />
      </IconButton>
    </div>
  );
};
