import { styled, Typography } from '@material-ui/core';
import { COLORS, MEDIA } from 'business/config/constants/styleVariables';
import React from 'react';

export const DefaultTypography = styled(Typography)({
  color: COLORS.BLACK,
  fontSize: '18px',
  fontWeight: 400,
});

export const ParagraphTypography = styled(Typography)({
  color: COLORS.BLUE,
  fontSize: '16px',
  fontWeight: 400,
});

export const DescriptionTypography = styled(Typography)({
  color: COLORS.GRAY,
  fontSize: '18px',
  fontWeight: 400,
  [`@media(max-width: ${MEDIA.MOBILE}px)`]: {
    fontSize: '10px',
    lineHeight: '12.4px',
  },
});

export const TitleTypography = styled(Typography)({
  color: COLORS.BLACK,
  fontSize: '26px',
  fontWeight: 700,
  [`@media(max-width: ${MEDIA.MOBILE}px)`]: {
    fontSize: '24px',
  },
});

export const TitleH3Typography = styled(Typography)({
  color: COLORS.BLACK,
  fontSize: '24px',
  fontWeight: 500,
  [`@media(max-width: ${MEDIA.MOBILE}px)`]: {
    fontSize: '20px',
  },
});
