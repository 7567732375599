import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: '32px',
  },
  title: {
    margin: 0,
  },
}));
