/*
 *
 * AdsPage reducer
 *
 */
import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { boolReducer } from 'common/helpers';
import { actions } from './actions';

export const initialState = {
  user: null,
  error: null,
};

const isLoading = boolReducer(
  [combineActions(actions.getUserRequest)],
  [combineActions(actions.getUserSuccess, actions.getUserFail)],
);

const data = handleActions(
  {
    [actions.getUserSuccess]: (state, { payload }) => ({
      ...state,
      user: payload,
    }),
    [actions.getUserFail]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  isLoading,
});

export const userPageSelectors = {
  user: (state) => (state.userPage ? state.userPage.data.user : null),
  error: (state) => (state.userPage ? state.userPage.data.error : null),
  isLoading: (state) => state.userPage.isLoading,
};
