import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { boolReducer } from 'common/helpers';
import { actions } from './actions';

export const initialState = {
  parentCategories: [],
  categories: [],
  categoriesObj: {},
  locations: [],
  locationsObj: {},
  allCategories: [],
  categoryAndChildren: {},
  cartProducts: [],
};

const isParentCategoriesLoading = boolReducer(
  [combineActions(actions.getParentCategoriesRequest)],
  [
    combineActions(
      actions.getParentCategoriesSuccess,
      actions.getParentCategoriesFail,
    ),
  ],
);

// const isCategoriesLoading = boolReducer(
//   [combineActions(actions.getCategoriesRequest, actions.getMainCategoriesRequest)],
//   [combineActions(
//     actions.getCategoriesSuccess,
//     actions.getCategoriesFail,
//     actions.getMainCategoriesSuccess,
//     actions.getMainCategoriesFail,
//     actions.getAllCategoriesSuccess,
//     actions.getAllCategoriesFail,
//     actions.getCategoryAndAllChildrenSuccess,
//     actions.getCategoryAndAllChildrenFail
//   )]
// )

const isLocationsLoading = boolReducer(
  [combineActions(actions.getLocationsRequest)],
  [combineActions(actions.getLocationsSuccess, actions.getLocationsFail)],
);

const data = handleActions(
  {
    [actions.getParentCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      parentCategories: payload,
    }),
    [actions.getCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      categories: Object.values(payload.tree),
      categoriesObj: payload.array.reduce(
        (acc, rec) => ({
          ...acc,
          [rec.id]: rec,
        }),
        {},
      ),
    }),
    [actions.getMainCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      mainCategories: payload,
    }),
    [actions.getLocationsSuccess]: (state, { payload }) => ({
      ...state,
      locations: Object.values(payload.tree),
      locationsObj: payload.array.reduce(
        (acc, rec) => ({
          ...acc,
          [rec.id]: rec,
        }),
        {},
      ),
    }),
    [actions.getAllCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      allCategories: payload,
    }),
    [actions.getCategoryAndAllChildrenSuccess]: (state, { payload }) => ({
      ...state,
      categoryAndChildren: payload,
    }),
    [actions.getCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      categories: payload,
      cartProducts: payload,
    }),
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  isParentCategoriesLoading,
  // isCategoriesLoading,
  isLocationsLoading,
});

export const appSelectors = {
  parentCategories: (state) =>
    state.app ? state.app.data.parentCategories : [],
  isParentCategoriesLoading: (state) => state.app.isParentCategoriesLoading,
  categories: (state) => (state.app ? state.app.data.categories : []),
  categoriesObj: (state) => (state.app ? state.app.data.categoriesObj : {}),
  mainCategories: (state) => (state.app ? state.app.data.mainCategories : []),
  mainCategoriesObj: (state) =>
    state.app ? state.app.data.mainCategoriesObj : {},
  isCategoriesLoading: (state) => state.app.isCategoriesLoading,
  locations: (state) => (state.app ? state.app.data.locations : []),
  locationsObj: (state) => (state.app ? state.app.data.locationsObj : {}),
  isLocationsLoading: (state) => state.app.isLocationsLoading,
  allCategories: (state) => (state.app ? state.app.data.allCategories : []),
  categoryAndChildren: (state) =>
    state.app ? state.app.data.categoryAndChildren : [],
  cartProducts: (state) => (state.app ? state.app.data : []),
};
